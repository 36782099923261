import { Letters } from "../components/Letters";
import { Keyboard } from "../components/Keyboard";
import styles from "./styles/Game.module.css";
import { observer } from "mobx-react-lite";

export const Game = observer(() => {
    return (
        <div className={styles.game}>
            <h1>wurrd</h1>
            <Letters />
            <Keyboard />
            <p>By Yetric AB</p>
        </div>
    );
});
