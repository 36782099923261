import { makeAutoObservable } from "mobx";

const LETTERS_PER_ROW = 5;
const ROWS_OF_LETTERS = 6;

export class GameStore {
    last: string = "";
    letter: number = 0;
    row: number = 0;
    letters: string[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    setLast(letter: string) {
        this.last = letter;

        if (this.lastCol && this.lastRow) {
            return;
        }

        this.letters.push(letter);

        if (this.lastCol) {
            this.letter = 0;
            this.row += 1;
        } else {
            this.letter += 1;
        }
    }

    get lastCol() {
        return this.letter === LETTERS_PER_ROW - 1;
    }

    get lastRow() {
        return this.row === ROWS_OF_LETTERS - 1;
    }

    isFocused(row: number, col: number) {
        return row === this.row && col === this.letter;
    }

    getLetter(row: number, col: number) {
        return this.letters[row * LETTERS_PER_ROW + col] || null;
    }
}
