import { GameStore } from "../stores/GameStore";
import { createContext, useContext } from "react";

const gameStore = new GameStore();

const stores = createContext({
  gameStore,
});

export const useStores = () => useContext(stores);
