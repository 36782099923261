import styles from "./styles/Letter.module.css";
import { observer } from "mobx-react-lite";
import { useStores } from "../hooks/useStore";
import clsx from "clsx";

interface LetterProps {
    row: number;
    col: number;
}

export const Letter = observer(({ row, col }: LetterProps) => {
    const { gameStore } = useStores();
    const cls = clsx(styles.letter, {
        [styles.focus]: gameStore.isFocused(row, col),
    });
    const value = gameStore.getLetter(row, col);
    return <span className={cls}>{value || ""}</span>;
});
