import {Key} from "./Key";
import styles from "./styles/Keyboard.module.css"
import {IoBackspaceOutline, IoReturnDownBackSharp} from "react-icons/io5";


interface RowProps {
    letters: string[];
    ret?: boolean;
    del?: boolean;
}

export const KeyRow = ({letters, ret, del}: RowProps) => {
    return <div className={styles.row}>
        {ret && <span className={`${styles.key} ${styles.double}`}><IoReturnDownBackSharp /></span>}
        {letters.map((l, index) => {
            return <Key letter={l} />
        })}
        {del && <span className={styles.key + " " + styles.double}><IoBackspaceOutline /></span>}
    </div>
}
