import React from 'react';
import {Game} from "./views/Game";
import "./index.css";

function App() {
  return (
      <div>
        <Game />
      </div>
  );
}

export default App;
