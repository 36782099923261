import styles from "./styles/Keyboard.module.css";
import { useStores } from "../hooks/useStore";

interface LetterProps {
    letter: string;
}

export const Key = ({ letter }: LetterProps) => {
    const { gameStore } = useStores();
    return (
        <span
            onClick={() => {
                gameStore.setLast(letter);
            }}
            className={styles.key}
        >
            {letter}
        </span>
    );
};
