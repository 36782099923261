import {KeyRow} from "./KeyRow";
import styles from "./styles/Keyboard.module.css";

export const Keyboard = () => {
    const letters = [
        "QWERTYUIOPÅ",
        "ASDFGHJKLÖÄ",
        "ZXCVBNM"
    ];

    return <div className={styles.keyboard}>
        <KeyRow letters={letters[0].split("")} />
        <KeyRow letters={letters[1].split("")} />
        <KeyRow letters={letters[2].split("")} ret={true} del={true} />
    </div>

}

