import { Letter } from "./Letter";
import styles from "./styles/Letter.module.css";

const arr = (size: number) => {
    return [...Array(size)];
};

export const Letters = () => {
    const getRow = (rowIndex: number) => {
        return (
            <div className={styles.row}>
                {arr(5).map((x, i) => (
                    <Letter row={rowIndex} col={i} />
                ))}
            </div>
        );
    };

    return <div className={styles.letters}>{arr(6).map((x, i) => getRow(i))}</div>;
};
